import create from './Constants';

const [
  COURSE_REQUEST,
  COURSE_SUCCESS,
  COURSE_FAIL,
] = create('COURSE');

const [
  COURSE_LIST_REQUEST,
  COURSE_LIST_SUCCESS,
  COURSE_LIST_FAIL,
] = create('COURSE_LIST');

export {
  COURSE_REQUEST,
  COURSE_SUCCESS,
  COURSE_FAIL,

  COURSE_LIST_REQUEST,
  COURSE_LIST_SUCCESS,
  COURSE_LIST_FAIL,
};
