import {
  PRODUCT_CONTENTS_FAIL,
  PRODUCT_CONTENTS_REQUEST,
  PRODUCT_CONTENTS_SUCCESS,
  PRODUCT_FAIL,
  PRODUCT_LESSON_FAIL,
  PRODUCT_LESSON_HISTORY_UPDATE_SUCCESS,
  PRODUCT_LESSON_REQUEST,
  PRODUCT_LESSON_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_REQUEST,
  PRODUCT_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS
} from '../constants/ProductConstants';

function productReducer(state={}, action) {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        products: {
          ...state.products,
          [action.id]: action.payload,
        }
      };
    case PRODUCT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PRODUCT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        product: {
          ...state.product,
          [action.id]: action.payload,
        }
      };
    case PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PRODUCT_UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case PRODUCT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        product: {
          ...state.product,
          [action.id]: action.payload,
        }
      };
    case PRODUCT_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PRODUCT_CONTENTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case PRODUCT_CONTENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        productContents: {
          ...state.productContents,
          [action.id]: action.payload,
        }
      };
    case PRODUCT_CONTENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PRODUCT_LESSON_REQUEST:
      return {
        ...state,
        loading: true
      };
    case PRODUCT_LESSON_SUCCESS:
      const lessons =
        state.productLessons?.hasOwnProperty(action.id) ?
          state.productLessons[action.id] : [];

      return {
        ...state,
        loading: false,
        productLessons: {
          ...state.productLessons,
          [action.id]: {
            ...lessons,
            [action.lessonId]: action.payload,
          },
        }
      };
    case PRODUCT_LESSON_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case PRODUCT_LESSON_HISTORY_UPDATE_SUCCESS: {
      const chapter = state.productContents[action.id].find(({id}) => id === action.chapterId);
      const chapterIndex = state.productContents[action.id].findIndex(({id}) => id === action.chapterId);
      const lessonIndex = chapter.lessons.findIndex(({ id }) => id === action.lessonId);
      const contents = state.productContents;

      contents[action.id][chapterIndex].lessons[lessonIndex] = {
        ...state.productContents[action.id][chapterIndex].lessons[lessonIndex],
        history: action.payload
      };

      return {
        ...state,
        productContents: contents,
      };
    }

    default:
      return state;
  }
}

export {productReducer};
