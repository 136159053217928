import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import thunk from 'redux-thunk';
import { userAuthReducer, userMeReducer, userReducer } from './redux/reducers/UserReducers';
import {courseReducer} from "./redux/reducers/CourseReducers";
import { paymentReducer, paymentRequestReducer } from './redux/reducers/PaymentReducers';
import { enrollmentReducer } from './redux/reducers/EnrollmentReducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { couponReducer } from './redux/reducers/CouponReducers';
import { userCouponReducer } from './redux/reducers/UserCouponReducers';
import { productReducer } from './redux/reducers/ProductReducers';
import { purchaseListReducer, purchaseReducer } from './redux/reducers/PurchaseReducers';
import { videoReducer } from './redux/reducers/VideoReducers';
import { releaseSubscriptionReducer } from './redux/reducers/ReleaseSubscriptionReducers';
import { postReducer } from './redux/reducers/PostReducers';
import { productCategoryReducer } from './redux/reducers/ProductCategoryReducers';
import { modalReducer } from './redux/reducers/ModalReducers';

const reducer = combineReducers({
  userAuth: userAuthReducer,
  userMe: userMeReducer,
  course: courseReducer,
  paymentRequest: paymentRequestReducer,
  enrollment: enrollmentReducer,
  userCoupon: userCouponReducer,
  product: productReducer,
  purchase: purchaseReducer,
  purchaseList: purchaseListReducer,
  video: videoReducer,
  releaseSubscription: releaseSubscriptionReducer,
  post: postReducer,

  user: userReducer,
  payment: paymentReducer,
  coupon: couponReducer,
  productCategory: productCategoryReducer,

  modal: modalReducer,
});

export const history = createBrowserHistory();

const middlewares = [
  thunk.withExtraArgument({ history: history }),
];
const enhancer = process.env.NODE_ENV === 'production' ?
  compose(applyMiddleware(...middlewares)) :
  composeWithDevTools(applyMiddleware(...middlewares));
const store = createStore(reducer, enhancer);

export default store;
