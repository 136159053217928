import create from './Constants';

const [
  PRODUCT_CATEGORY_LIST_REQUEST,
  PRODUCT_CATEGORY_LIST_SUCCESS,
  PRODUCT_CATEGORY_LIST_FAIL,
] = create('PRODUCT_CATEGORY_LIST');

const [
  PRODUCT_CATEGORY_ADD_REQUEST,
  PRODUCT_CATEGORY_ADD_SUCCESS,
  PRODUCT_CATEGORY_ADD_FAIL,
] = create('PRODUCT_CATEGORY_ADD');

export {
  PRODUCT_CATEGORY_LIST_REQUEST,
  PRODUCT_CATEGORY_LIST_SUCCESS,
  PRODUCT_CATEGORY_LIST_FAIL,

  PRODUCT_CATEGORY_ADD_REQUEST,
  PRODUCT_CATEGORY_ADD_SUCCESS,
  PRODUCT_CATEGORY_ADD_FAIL,
};
