import {
  PURCHASE_FAIL, PURCHASE_LIST_FAIL,
  PURCHASE_LIST_REQUEST, PURCHASE_LIST_SUCCESS,
  PURCHASE_REQUEST,
  PURCHASE_SUCCESS
} from '../constants/PurchaseConstants';

function purchaseReducer(state={}, action) {
  switch (action.type) {
    case PURCHASE_REQUEST:
      return {
        loading: true
      };
    case PURCHASE_SUCCESS:
      return {
        loading: false,
        data: action.payload
      };
    case PURCHASE_FAIL:
      return {
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

function purchaseListReducer(state={}, action) {
  switch (action.type) {
    case PURCHASE_LIST_REQUEST:
      return {
        loading: true
      }
    case PURCHASE_LIST_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      }
    case PURCHASE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}

export {
  purchaseReducer,
  purchaseListReducer,
};
