import create from './Constants';

const [
  PURCHASE_REQUEST,
  PURCHASE_SUCCESS,
  PURCHASE_FAIL,
] = create('PURCHASE');


const [
  PURCHASE_LIST_REQUEST,
  PURCHASE_LIST_SUCCESS,
  PURCHASE_LIST_FAIL,
] = create('PURCHASE_LIST');

export {
  /*   Purchase  */
  PURCHASE_REQUEST,
  PURCHASE_SUCCESS,
  PURCHASE_FAIL,

  /*   Purchase List  */
  PURCHASE_LIST_REQUEST,
  PURCHASE_LIST_SUCCESS,
  PURCHASE_LIST_FAIL,
};
