import create from './Constants';

const [
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL
] = create('USER_LIST');

const [
  USER_SAVE_REQUEST,
  USER_SAVE_SUCCESS,
  USER_SAVE_FAIL
] = create('USER_SAVE');

const [
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL
] = create('USER_LOGIN');

const USER_LOGOUT = 'USER_LOGOUT';

const [
  USER_ME_FETCH_REQUEST,
  USER_ME_FETCH_SUCCESS,
  USER_ME_FETCH_FAIL
] = create('USER_ME_FETCH');

const [
  USER_ME_SAVE_REQUEST,
  USER_ME_SAVE_SUCCESS,
  USER_ME_SAVE_FAIL
] = create('USER_ME_SAVE');

export {
  /*      User       */
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,

  USER_SAVE_REQUEST,
  USER_SAVE_SUCCESS,
  USER_SAVE_FAIL,

  /*    User Login     */
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,

  /*    User Me     */
  USER_ME_FETCH_REQUEST,
  USER_ME_FETCH_SUCCESS,
  USER_ME_FETCH_FAIL,

  USER_ME_SAVE_REQUEST,
  USER_ME_SAVE_SUCCESS,
  USER_ME_SAVE_FAIL,
};
