import React, { useEffect } from 'react';
import {
  Route,
} from "react-router-dom";
import Navigation from '../../containers/Navigation';
import Footer from '../../containers/Footer';

const ExtendedRoute = ({component, theme,
                         nav, footer, channelTalk=true,
                         ...props}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {nav && <Navigation theme={theme} />}
      <Route {...props}
             component={component}
      />
      {footer && (
        <Footer channelTalk={channelTalk}
                theme={theme}
        />
      )}
    </>
  );
}

export default ExtendedRoute;
