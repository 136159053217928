import Axios from 'axios';
import { currentUserSubject } from './redux/reducers/UserReducers';

const instance = Axios.create({
  baseURL: process.env.REACT_APP_CREATIVE_STATES_EDU_API_URL,
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  if (currentUserSubject.value?.token) {
    config.headers.Authorization = `Bearer ${currentUserSubject.value.token}`;
  }

  return config;
});

export default instance;
