import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store, { history } from './store';
import { Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import CFonts from 'cfonts';

// [ React GA ]
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, { debug: process.env.NODE_ENV !== 'production' });
ReactGA.plugin.require('ec');
ReactGA.set({ page: window.location.pathname });
ReactGA.pageview(window.location.pathname);
//

// [ React Facebook Pixel ]
const advancedMatching = { }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: false, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: process.env.NODE_ENV !== 'production', // enable logs
};

ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID, advancedMatching, options);
ReactPixel.pageView();
ReactPixel.fbq('track', 'PageView');
//

history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
  ReactPixel.pageView();
  ReactPixel.fbq('track', 'PageView');
  window.scrollTo(0, 0);
});

CFonts.say('Creative|States', {
  font: 'block',              // define the font face
  align: 'left',              // define text alignment
  colors: ['system'],         // define all colors
  background: 'transparent',  // define the background color, you can also use `backgroundColor` here as key
  letterSpacing: 1,           // define letter spacing
  lineHeight: 1,              // define the line height
  space: true,                // define if the output text should have empty lines on top and on the bottom
  maxLength: '0',             // define how many character can be on one line
  gradient: false,            // define your two gradient colors
  independentGradient: false, // define if you want to recalculate the gradient for each new line
  transitionGradient: false,  // define if this is a transition between colors directly
  env: 'node'                 // define the environment CFonts is being executed in
});

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
