import React from 'react';
import style from './style.module.scss';
import Logo from '../../components/Logo';
import LogoImg from '../../img/creative-states-logo.png';
import LogoWhiteImg from '../../img/creative-states-logo-white.png';
import Container from '@material-ui/core/Container';
import cn from 'classnames';

const Navigation = ({ theme }) => {
  return (
    <div className={cn(style.fixedNav, {
      [style.dark]: theme === 'dark'
    })}>
      <Container>
        <div className={style.containerWrap}>
          <div className={style.leftSide}>
            <Logo className={style.logo}
                  src={theme === 'dark' ? LogoWhiteImg : LogoImg}
                  alt={'Creative States'}
                  href={'/'}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Navigation;
