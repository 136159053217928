import {
  PAYMENT_FAIL,
  PAYMENT_LIST_FAIL,
  PAYMENT_LIST_REQUEST,
  PAYMENT_LIST_SUCCESS, PAYMENT_REFUND_FAIL, PAYMENT_REFUND_REQUEST, PAYMENT_REFUND_SUCCESS,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS
} from '../constants/PaymentConstants';

function paymentReducer(state = {}, action) {
  switch (action.type) {
    case PAYMENT_LIST_REQUEST:
      return {
        loading: true
      };
    case PAYMENT_LIST_SUCCESS:
      return {
        loading: false, payments: action.payload.items, total: action.payload.total
      };
    case PAYMENT_LIST_FAIL:
      return {
        loading: false, error: action.payload
      };

    case PAYMENT_REFUND_REQUEST:
      return {
        ...state,
        loading: true,
        isAlert: false,
      };
    case PAYMENT_REFUND_SUCCESS:
      const payments = state.payments.map(payment => {
        if (payment.id === action.payload.id) {
          return action.payload;
        }
        return payment;
      });
      return {
        ...state,
        loading: false,
        payments,
        isAlert: true,
      };
    case PAYMENT_REFUND_FAIL:
      return {
        ...state,
        loading: false, error: action.payload,
        isAlert: true,
      };

    default:
      return state;
  }
}

function paymentRequestReducer(state={}, action) {
  switch (action.type) {
    case PAYMENT_REQUEST:
      return {loading: true};
    case PAYMENT_SUCCESS:
      return {loading: false, data: action.payload};
    case PAYMENT_FAIL:
      return {loading: false, error: action.payload};
    default:
      return state;
  }
}

export {
  paymentReducer,
  paymentRequestReducer,
};
