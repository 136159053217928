import { MODAL_CLOSE, MODAL_LOADING, MODAL_OPEN } from '../constants/ModalConstants';

function modalReducer(state = {}, action) {
  switch (action.type) {
    case MODAL_OPEN:
      return {
        ...state,
        [action.name]: {
          open: true,
          loading: false,
          data: action.data,
        },
      };
    case MODAL_CLOSE:
      return {
        ...state,
        [action.name]: {
          open: false,
          loading: false,
          data: {},
        },
      };
    case MODAL_LOADING:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          loading: action.loading,
        },
      };

    default:
      return state;
  }
}

export {
  modalReducer,
};
