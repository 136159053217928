import {
  COUPON_ADD_FAIL,
  COUPON_ADD_REQUEST, COUPON_ADD_SUCCESS, COUPON_GET_FAIL, COUPON_GET_REQUEST, COUPON_GET_SUCCESS,
  COUPON_LIST_FAIL,
  COUPON_LIST_REQUEST,
  COUPON_LIST_SUCCESS, COUPON_SAVE_FAIL, COUPON_SAVE_REQUEST, COUPON_SAVE_SUCCESS
} from '../constants/CouponConstants';

function couponReducer(state = {}, action) {
  switch (action.type) {
    case COUPON_LIST_REQUEST:
      return {
        loading: true
      };
    case COUPON_LIST_SUCCESS:
      return {
        loading: false, coupons: action.payload.items, total: action.payload.total
      };
    case COUPON_LIST_FAIL:
      return {
        loading: false, error: action.payload
      };

    case COUPON_GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case COUPON_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        coupon: action.payload,
      };
    case COUPON_GET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case COUPON_ADD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case COUPON_ADD_SUCCESS:
      return {
        ...state,
        loading: false, newCoupon: action.payload,
      };
    case COUPON_ADD_FAIL:
      return {
        ...state,
        loading: false, error: action.payload
      };

    case COUPON_SAVE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case COUPON_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        newCoupon: action.payload,
        coupons: state.coupons.map(coupon => {
          if (coupon.id === action.payload.id) {
            return action.payload;
          }
          return coupon;
        }),
      };
    case COUPON_SAVE_FAIL:
      return {
        ...state,
        loading: false, error: action.payload
      };

    default:
      return state;
  }
}

export {
  couponReducer,
};
