import {
  POST_ADD_FAIL,
  POST_ADD_REQUEST,
  POST_ADD_SUCCESS,
  POST_FAIL,
  POST_LIST_FAIL, POST_LIST_INIT,
  POST_LIST_REQUEST,
  POST_LIST_SUCCESS,
  POST_REQUEST,
  POST_SUCCESS
} from '../constants/PostConstants';

function postReducer(state={}, action) {
  switch (action.type) {
    case POST_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case POST_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        posts: {
          total: action.payload.total,
          items: state?.posts?.items ?
            state.posts.items.concat(action.payload.items)
            : action.payload.items
        }
      };
    case POST_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case POST_LIST_INIT:
      return {
        ...state,
        posts: null,
      };

    case POST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case POST_SUCCESS:
      return {
        ...state,
        loading: false,
        post: {
          ...state.post,
          [action.id]: action.payload,
        },
      };
    case POST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case POST_ADD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case POST_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        post: {
          ...state.post,
          [action.id]: action.payload,
        },
      };
    case POST_ADD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

export {postReducer};
