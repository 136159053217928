import {
  USER_COUPON_LIST_FAIL,
  USER_COUPON_LIST_REQUEST,
  USER_COUPON_LIST_SUCCESS,
  SET_COUPON_REDEMPTION,
  USE_COUPON
} from '../constants/UserCouponConstants';

function userCouponReducer(state = {
  redemptionModal: false,
}, action) {
  switch (action.type) {
    case USER_COUPON_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_COUPON_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        coupons: action.payload
      };
    case USER_COUPON_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case SET_COUPON_REDEMPTION:
      return {
        ...state,
        redemptionModal: action.payload
      };

    case USE_COUPON:
      return {
        ...state,
        useCoupon: action.payload
      };

    default:
      return state;
  }
}

export {
  userCouponReducer,
};
