import create from './Constants';

const POST_LIST_INIT = 'POST_LIST_INIT';

const [
  POST_REQUEST,
  POST_SUCCESS,
  POST_FAIL,
] = create('POST');

const [
  POST_LIST_REQUEST,
  POST_LIST_SUCCESS,
  POST_LIST_FAIL,
] = create('POST_LIST');

const [
  POST_ADD_REQUEST,
  POST_ADD_SUCCESS,
  POST_ADD_FAIL,
] = create('POST_ADD');

const [
  POST_EDIT_REQUEST,
  POST_EDIT_SUCCESS,
  POST_EDIT_FAIL,
] = create('POST_EDIT');

const [
  POST_DELETE_REQUEST,
  POST_DELETE_SUCCESS,
  POST_DELETE_FAIL,
] = create('POST_DELETE');

export {
  POST_REQUEST,
  POST_SUCCESS,
  POST_FAIL,

  POST_LIST_INIT,

  POST_LIST_REQUEST,
  POST_LIST_SUCCESS,
  POST_LIST_FAIL,

  POST_ADD_REQUEST,
  POST_ADD_SUCCESS,
  POST_ADD_FAIL,

  POST_EDIT_REQUEST,
  POST_EDIT_SUCCESS,
  POST_EDIT_FAIL,

  POST_DELETE_REQUEST,
  POST_DELETE_SUCCESS,
  POST_DELETE_FAIL,
};
