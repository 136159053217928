import {
  COURSE_FAIL, COURSE_LIST_FAIL,
  COURSE_LIST_REQUEST,
  COURSE_LIST_SUCCESS,
  COURSE_REQUEST,
  COURSE_SUCCESS
} from '../constants/CourseConstants';

function courseReducer(state={}, action) {
  switch (action.type) {
    case COURSE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case COURSE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: action.payload
      };
    case COURSE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case COURSE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        course: action.payload
      };
    case COURSE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

export {courseReducer};
