import {
  ENROLL_FAIL,
  ENROLL_REQUEST, ENROLL_SUCCESS,
  ENROLLMENT_FAIL, ENROLLMENT_LIST_FAIL, ENROLLMENT_LIST_REQUEST, ENROLLMENT_LIST_SUCCESS,
  ENROLLMENT_REQUEST,
  ENROLLMENT_SUCCESS
} from '../constants/EnrollmentConstants';

function enrollmentReducer(state={
  enroll: false,
}, action) {
  switch (action.type) {
    case ENROLLMENT_LIST_REQUEST:
      return {
        ...state,
        enrollments: [],
        loading: true
      };
    case ENROLLMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        enrollments: action.payload
      };
    case ENROLLMENT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        enrollments: [],
        error: action.payload
      };

    case ENROLLMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ENROLLMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case ENROLLMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ENROLL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ENROLL_SUCCESS:
      return {
        ...state,
        loading: false,
        enroll: true
      };
    case ENROLL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

export {enrollmentReducer};
