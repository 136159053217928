import React from 'react';
import style from './style.module.scss';
import { Link } from 'react-router-dom';

const Logo = ({src, href, alt, className}) => {
  return (
    <Link to={href}>
      <img
        className={className}
        src={src}
        alt={alt} />
    </Link>
  );
}

export default Logo;
