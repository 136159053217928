import create from './Constants';

const [
  RELEASE_SUBSCRIPTION_REQUEST,
  RELEASE_SUBSCRIPTION_SUCCESS,
  RELEASE_SUBSCRIPTION_FAIL,
] = create('RELEASE_SUBSCRIPTION');

const SET_RELEASE_SUBSCRIPTION_MODAL = 'SET_RELEASE_SUBSCRIPTION_MODAL';

export {
  /*   Release Subscription Request  */
  RELEASE_SUBSCRIPTION_REQUEST,
  RELEASE_SUBSCRIPTION_SUCCESS,
  RELEASE_SUBSCRIPTION_FAIL,

  SET_RELEASE_SUBSCRIPTION_MODAL,
};
