import create from './Constants';

const [
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
] = create('PRODUCT_LIST');

const [
  PRODUCT_REQUEST,
  PRODUCT_SUCCESS,
  PRODUCT_FAIL,
] = create('PRODUCT');

const [
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
] = create('PRODUCT_UPDATE');

const [
  PRODUCT_CONTENTS_REQUEST,
  PRODUCT_CONTENTS_SUCCESS,
  PRODUCT_CONTENTS_FAIL,
] = create('PRODUCT_CONTENTS');

const [
  PRODUCT_LESSON_REQUEST,
  PRODUCT_LESSON_SUCCESS,
  PRODUCT_LESSON_FAIL,
] = create('PRODUCT_LESSON');

const [
  PRODUCT_LESSON_HISTORY_UPDATE_REQUEST,
  PRODUCT_LESSON_HISTORY_UPDATE_SUCCESS,
  PRODUCT_LESSON_HISTORY_UPDATE_FAIL,
] = create('PRODUCT_LESSON_HISTORY_UPDATE');

/*
 *        Chapter
 */

const [
  PRODUCT_CHAPTER_ADD_REQUEST,
  PRODUCT_CHAPTER_ADD_SUCCESS,
  PRODUCT_CHAPTER_ADD_FAIL,
] = create('PRODUCT_CHAPTER_ADD');

const [
  PRODUCT_CHAPTER_UPDATE_REQUEST,
  PRODUCT_CHAPTER_UPDATE_SUCCESS,
  PRODUCT_CHAPTER_UPDATE_FAIL,
] = create('PRODUCT_CHAPTER_UPDATE');

const [
  PRODUCT_CHAPTER_DELETE_REQUEST,
  PRODUCT_CHAPTER_DELETE_SUCCESS,
  PRODUCT_CHAPTER_DELETE_FAIL,
] = create('PRODUCT_CHAPTER_DELETE');

/*
 *        Lesson
 */

const [
  PRODUCT_LESSON_ADD_REQUEST,
  PRODUCT_LESSON_ADD_SUCCESS,
  PRODUCT_LESSON_ADD_FAIL,
] = create('PRODUCT_LESSON_ADD');

const [
  PRODUCT_LESSON_UPDATE_REQUEST,
  PRODUCT_LESSON_UPDATE_SUCCESS,
  PRODUCT_LESSON_UPDATE_FAIL,
] = create('PRODUCT_LESSON_UPDATE');

const [
  PRODUCT_LESSON_DELETE_REQUEST,
  PRODUCT_LESSON_DELETE_SUCCESS,
  PRODUCT_LESSON_DELETE_FAIL,
] = create('PRODUCT_LESSON_DELETE');

export {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,

  PRODUCT_REQUEST,
  PRODUCT_SUCCESS,
  PRODUCT_FAIL,

  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,

  PRODUCT_CONTENTS_REQUEST,
  PRODUCT_CONTENTS_SUCCESS,
  PRODUCT_CONTENTS_FAIL,

  PRODUCT_LESSON_REQUEST,
  PRODUCT_LESSON_SUCCESS,
  PRODUCT_LESSON_FAIL,

  PRODUCT_LESSON_HISTORY_UPDATE_REQUEST,
  PRODUCT_LESSON_HISTORY_UPDATE_SUCCESS,
  PRODUCT_LESSON_HISTORY_UPDATE_FAIL,

  PRODUCT_CHAPTER_ADD_REQUEST,
  PRODUCT_CHAPTER_ADD_SUCCESS,
  PRODUCT_CHAPTER_ADD_FAIL,

  PRODUCT_CHAPTER_UPDATE_REQUEST,
  PRODUCT_CHAPTER_UPDATE_SUCCESS,
  PRODUCT_CHAPTER_UPDATE_FAIL,

  PRODUCT_CHAPTER_DELETE_REQUEST,
  PRODUCT_CHAPTER_DELETE_SUCCESS,
  PRODUCT_CHAPTER_DELETE_FAIL,

  PRODUCT_LESSON_ADD_REQUEST,
  PRODUCT_LESSON_ADD_SUCCESS,
  PRODUCT_LESSON_ADD_FAIL,

  PRODUCT_LESSON_UPDATE_REQUEST,
  PRODUCT_LESSON_UPDATE_SUCCESS,
  PRODUCT_LESSON_UPDATE_FAIL,

  PRODUCT_LESSON_DELETE_REQUEST,
  PRODUCT_LESSON_DELETE_SUCCESS,
  PRODUCT_LESSON_DELETE_FAIL,
};
