import {
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_SAVE_FAIL,
  USER_SAVE_REQUEST,
  USER_SAVE_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_ME_FETCH_REQUEST,
  USER_ME_FETCH_SUCCESS,
  USER_ME_FETCH_FAIL,
  USER_ME_SAVE_REQUEST,
  USER_ME_SAVE_SUCCESS, USER_ME_SAVE_FAIL
} from '../constants/UserConstants';
import Axios from '../../axios';
import axios from 'axios';
import { currentUserSubject } from '../reducers/UserReducers';
import contentRange from 'content-range';
import {local} from '../../storage';


const getUsers =
  ({page = 1, limit = 10, q}) =>
    async (dispatch) => {
      try {
        dispatch({ type: USER_LIST_REQUEST, payload: null });

        const { headers, data } = await Axios.get('/admin/users', {
          params: {
            limit,
            offset: (page - 1) * limit,
            q,
          }
        });
        const parts = contentRange.parse(headers['content-range']);
        dispatch({
          type: USER_LIST_SUCCESS, payload: {
            total: parts.length,
            items: data,
          }
        });
      } catch (error) {
        const message = error.response.data?.error;
        alert(message || '회원 목록을 가져오지 못했습니다!');
        dispatch({ type: USER_LIST_FAIL, payload: error });
      }
    };

const saveUser =
  (id, user) =>
    async (dispatch) => {
      try {
        dispatch({ type: USER_SAVE_REQUEST, payload: null });

        const { data } = await Axios.patch(`/admin/users/${id}`, user);
        dispatch({ type: USER_SAVE_SUCCESS, payload: data });
        alert('회원정보를 성공적으로 수정하였습니다.');
      } catch (error) {
        const message = error.response.data?.error;
        alert(message || '회원 정보을 저장하지 못했습니다!');
        dispatch({ type: USER_SAVE_FAIL, payload: error });
      }
    };


const socialLogin =
  async (dispatch, url, code) => {
    try {
      dispatch({ type: USER_LOGIN_REQUEST, payload: code });

      const { data } = await Axios.post(url, code);
      dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
      const {firstLogin} = data.user;
      if (!firstLogin) {
        local.setItem('user', JSON.stringify(data));
        currentUserSubject.next(data);
      }
      dispatch(me());
    } catch (error) {
      alert('로그인을 실패했습니다!');
      dispatch({ type: USER_LOGIN_FAIL, payload: error });
    }
  };

const googleLogin =
  (code) =>
    async (dispatch) => {
      await socialLogin(dispatch, '/auth/google/authcode', { code });
    };

const kakaoLogin =
  (accessToken) =>
    async (dispatch) => {
      await socialLogin(dispatch, '/auth/kakao', { accessToken });
    };

const naverLogin =
  (accessToken) =>
    async (dispatch) => {
      await socialLogin(dispatch, '/auth/naver', { accessToken });
    };

const me =
  () =>
    async (dispatch, getState) => {
      try {
        const {token} = getState()?.userAuth?.user;

        dispatch({ type: USER_ME_FETCH_REQUEST });
        let config = {};
        if (token) {
          config = {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
        }

        const { data } = await axios.get(process.env.REACT_APP_CREATIVE_STATES_EDU_API_URL + '/users/me', config);
        dispatch({ type: USER_ME_FETCH_SUCCESS, payload: data });
      } catch (error) {
        alert('사용자 정보를 불러올 수 없습니다!');
        dispatch({ type: USER_ME_FETCH_FAIL, payload: error });
      }
    };

const saveMe =
  (user, showAlert=true) =>
    async (dispatch, getState) => {
      try {
        const {token} = getState()?.userAuth?.user;

        dispatch({ type: USER_ME_SAVE_REQUEST });
        let config = {};
        if (token) {
          config = {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
        }

        const { data } = await axios.patch(process.env.REACT_APP_CREATIVE_STATES_EDU_API_URL + '/users/me', user, config);
        dispatch({ type: USER_ME_SAVE_SUCCESS, payload: data });

        const {firstLogin} = data;
        if (!firstLogin) {
          local.setItem('user', JSON.stringify({
            user: data,
            token,
          }));
          currentUserSubject.next({
            user: data,
            token,
          });
        }

        if (showAlert) {
          alert('내 프로필을 수정하였습니다.');
        }
        dispatch(me());
      } catch (error) {
        const message = error.response?.data?.error;
        if (message) {
          alert(message);
        }
        dispatch({ type: USER_ME_SAVE_FAIL, payload: message });
      }
    };

const logout =
  () =>
    (dispatch) => {
      local.clear('user');
      dispatch({type: USER_LOGOUT});
    };

export {
  getUsers,
  saveUser,

  googleLogin,
  kakaoLogin,
  naverLogin,
  me,
  saveMe,
  logout
};
