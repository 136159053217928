import {
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_ME_FETCH_FAIL,
  USER_ME_FETCH_REQUEST,
  USER_ME_FETCH_SUCCESS, USER_ME_SAVE_FAIL,
  USER_ME_SAVE_REQUEST,
  USER_ME_SAVE_SUCCESS,
  USER_SAVE_FAIL,
  USER_SAVE_REQUEST,
  USER_SAVE_SUCCESS
} from '../constants/UserConstants';
import {BehaviorSubject} from "rxjs";
import {local} from '../../storage';

const currentUserSubject = new BehaviorSubject(JSON.parse(local.getItem('user')));

const initialState = currentUserSubject.value ?
  {isLoggedIn: true, user: currentUserSubject.value} :
  {isLoggedIn: false, user: null};

function userReducer(state = {}, action) {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return {
        loading: true
      };
    case USER_LIST_SUCCESS:
      return {
        loading: false, users: action.payload.items, total: action.payload.total
      };
    case USER_LIST_FAIL:
      return {
        loading: false, error: action.payload
      };

    case USER_SAVE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case USER_SAVE_SUCCESS:
      const users = state.users.map(user => {
        if (user.id === action.payload.id) {
          return action.payload;
        }
        return user;
      });
      return {
        ...state,
        loading: false, users,
      };
    case USER_SAVE_FAIL:
      return {
        ...state,
        loading: false, error: action.payload
      };

    default:
      return state;
  }
}

function userAuthReducer(state=initialState, action) {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {loading: true};
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        isLoggedIn: true,
        user: action.payload
      };
    case USER_LOGIN_FAIL:
      return {loading: false, error: action.payload};
    case USER_LOGOUT:
      return {isLoggedIn: false, user: null};
    default:
      return state;
  }
}

function userMeReducer(state=initialState, action) {
  switch (action.type) {
    case USER_ME_FETCH_REQUEST:
      return {loading: true};
    case USER_ME_FETCH_SUCCESS:
      return {
        loading: false,
        firstLogin: action.payload.firstLogin,
        user: action.payload
      };
    case USER_ME_FETCH_FAIL:
      return {loading: false, error: action.payload};

    case USER_ME_SAVE_REQUEST:
      return {...state, saveLoading: true};
    case USER_ME_SAVE_SUCCESS:
      return {
        ...state,
        saveLoading: false,
        firstLogin: action.payload.firstLogin,
        user: action.payload
      };
    case USER_ME_SAVE_FAIL:
      return {...state, saveLoading: false, error: action.payload};
    case USER_LOGOUT:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
}

export {
  userReducer,
  userAuthReducer,
  userMeReducer,
  currentUserSubject
};
