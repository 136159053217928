import create from './Constants';

const [
  ENROLLMENT_LIST_REQUEST,
  ENROLLMENT_LIST_SUCCESS,
  ENROLLMENT_LIST_FAIL,
] = create('ENROLLMENT_LIST');

const [
  ENROLLMENT_REQUEST,
  ENROLLMENT_SUCCESS,
  ENROLLMENT_FAIL,
] = create('ENROLLMENT');

const [
  ENROLL_REQUEST,
  ENROLL_SUCCESS,
  ENROLL_FAIL,
] = create('ENROLL');

export {
  ENROLLMENT_LIST_REQUEST,
  ENROLLMENT_LIST_SUCCESS,
  ENROLLMENT_LIST_FAIL,

  ENROLLMENT_REQUEST,
  ENROLLMENT_SUCCESS,
  ENROLLMENT_FAIL,

  ENROLL_REQUEST,
  ENROLL_SUCCESS,
  ENROLL_FAIL,
};
