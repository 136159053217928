import create from './Constants';

const [
  COUPON_LIST_REQUEST,
  COUPON_LIST_SUCCESS,
  COUPON_LIST_FAIL
] = create('COUPON_LIST');

const [
  COUPON_GET_REQUEST,
  COUPON_GET_SUCCESS,
  COUPON_GET_FAIL
] = create('COUPON_GET');

const [
  COUPON_ADD_REQUEST,
  COUPON_ADD_SUCCESS,
  COUPON_ADD_FAIL
] = create('COUPON_ADD');

const [
  COUPON_SAVE_REQUEST,
  COUPON_SAVE_SUCCESS,
  COUPON_SAVE_FAIL,
] = create('COUPON_SAVE');

export {
  /*      Coupon       */
  COUPON_LIST_REQUEST,
  COUPON_LIST_SUCCESS,
  COUPON_LIST_FAIL,

  COUPON_GET_REQUEST,
  COUPON_GET_SUCCESS,
  COUPON_GET_FAIL,

  COUPON_ADD_REQUEST,
  COUPON_ADD_SUCCESS,
  COUPON_ADD_FAIL,

  COUPON_SAVE_REQUEST,
  COUPON_SAVE_SUCCESS,
  COUPON_SAVE_FAIL,
};
