import create from './Constants';

const [
  PAYMENT_LIST_REQUEST,
  PAYMENT_LIST_SUCCESS,
  PAYMENT_LIST_FAIL
] = create('PAYMENT_LIST');

const [
  PAYMENT_REFUND_REQUEST,
  PAYMENT_REFUND_SUCCESS,
  PAYMENT_REFUND_FAIL
] = create('PAYMENT_REFUND');

const [
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  PAYMENT_FAIL,
] = create('PAYMENT');

export {
  /*      Payment       */
  PAYMENT_LIST_REQUEST,
  PAYMENT_LIST_SUCCESS,
  PAYMENT_LIST_FAIL,

  /*      Refund       */
  PAYMENT_REFUND_REQUEST,
  PAYMENT_REFUND_SUCCESS,
  PAYMENT_REFUND_FAIL,

  /*   Payment Request  */
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  PAYMENT_FAIL,
};
