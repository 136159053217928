import {
  RELEASE_SUBSCRIPTION_FAIL,
  RELEASE_SUBSCRIPTION_REQUEST,
  RELEASE_SUBSCRIPTION_SUCCESS, SET_RELEASE_SUBSCRIPTION_MODAL
} from '../constants/ReleaseSubscriptionConstants';

function releaseSubscriptionReducer(state={}, action) {
  switch (action.type) {
    case SET_RELEASE_SUBSCRIPTION_MODAL:
      return {
        ...state,
        modalOpen: action.payload,
      }
    case RELEASE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case RELEASE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case RELEASE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

export {
  releaseSubscriptionReducer,
};
