import React, { lazy, Suspense } from 'react';
import './App.css';
import { Switch } from 'react-router-dom';
import ExtendedRoute from './components/ExtendedRoute';

const TerminationPage = lazy(() => import('./pages/TerminationPage'));

function App() {
  return (
    <div>
      <Suspense fallback={''}>
        <Switch>
          <ExtendedRoute nav exact path="/termination" component={TerminationPage} theme={'dark'} />

          <ExtendedRoute nav exact path="*" component={TerminationPage} theme={'dark'} />
        </Switch>
      </Suspense>
    </div>
  );
}

export default App;
