import create from './Constants';

const [
  VIDEO_REQUEST,
  VIDEO_SUCCESS,
  VIDEO_FAIL,
] = create('VIDEO');

const [
  VIDEO_ADD_REQUEST,
  VIDEO_ADD_SUCCESS,
  VIDEO_ADD_FAIL,
] = create('VIDEO_ADD');

export {
  VIDEO_REQUEST,
  VIDEO_SUCCESS,
  VIDEO_FAIL,

  VIDEO_ADD_REQUEST,
  VIDEO_ADD_SUCCESS,
  VIDEO_ADD_FAIL,
};
