import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { ChannelTalk } from 'react-channel-plugin';
import { saveMe } from '../../redux/actions/UserActions';

const SecureChannelTalk = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.userMe);
  const user = userInfo?.user == null ? null : userInfo.user;
  const channelTalkCookies = [`ch-session-${process.env.REACT_APP_CHANNEL_TALK_CHANNEL_ID}`, 'x-veil-id', 'ch-veil-id'];
  const [isLoading, setLoading] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(channelTalkCookies);

  const onBoot = useCallback((profile) => {
    if (!user) {
      return;
    }
    const {memberId} = profile;

    dispatch(saveMe({
      channelTalk: {
        memberId
      }
    }, false));
  }, [user]);

  useEffect(() => {
    if (!user) {
      channelTalkCookies.forEach(cookieName => {
        removeCookie(cookieName);
      });
    }
    setLoading(true);
  }, [user]);

  useEffect(() => {
    return () => {
      document.getElementById('ch-plugin')?.remove();
    }
  }, []);

  return (
    <>
      {isLoading &&
      <>
        {!!user?.channelTalk ? (
            <ChannelTalk pluginKey={process.env.REACT_APP_CHANNEL_TALK_PLUGIN_KEY}
                         locale={'ko'}
                         onBoot={onBoot}
                         userId={user?.channelTalk?.memberId}
                         memberHash={user?.channelTalk?.memberHash}
                         profile={{
                           name: user?.name || user?.nickname || '',
                           email: user?.email || '',
                           mobileNumber: user?.phone || '',
                           avatarUrl: user?.profileImage || '',
                           referrer: window.location.origin
                         }}
            />
          ) :
          (
            <ChannelTalk pluginKey={process.env.REACT_APP_CHANNEL_TALK_PLUGIN_KEY}
                         locale={'ko'}
                         onBoot={onBoot}
                         userId={''}
                         profile={{
                           referrer: window.location.origin
                         }}
            />
          )}
      </>}
    </>
  );
}

export default SecureChannelTalk;
