import create from './Constants';

const [
  USER_COUPON_LIST_REQUEST,
  USER_COUPON_LIST_SUCCESS,
  USER_COUPON_LIST_FAIL
] = create('USER_COUPON_LIST');

const [
  USER_COUPON_ADD_REQUEST,
  USER_COUPON_ADD_SUCCESS,
  USER_COUPON_ADD_FAIL
] = create('USER_COUPON_ADD');

const SET_COUPON_REDEMPTION = 'SET_COUPON_REDEMPTION';
const USE_COUPON = 'USE_COUPON';

export {
  /*      Coupon       */
  USER_COUPON_LIST_REQUEST,
  USER_COUPON_LIST_SUCCESS,
  USER_COUPON_LIST_FAIL,

  USER_COUPON_ADD_REQUEST,
  USER_COUPON_ADD_SUCCESS,
  USER_COUPON_ADD_FAIL,

  SET_COUPON_REDEMPTION,

  USE_COUPON,
};
