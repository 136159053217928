import React from 'react';
import style from './style.module.scss';
import { Container } from '@material-ui/core';
import Logo from '../../components/Logo';
import LogoImg from '../../img/creative-states-logo.png';
import LogoWhiteImg from '../../img/creative-states-logo-white.png';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import ExternalLink from '../../components/ExternalLink';
import SecureChannelTalk from '../SecureChannelTalk';
import cn from 'classnames';
import { Link } from 'react-router-dom';

const Footer = ({channelTalk=true, theme}) => {
  const socialLinks = [
    {
      id: 'instagram',
      link: 'https://www.instagram.com/accounts/login/?next=/creativestates_official/',
      icon: (<InstagramIcon/>),
    },
    {
      id: 'facebook',
      link: 'https://www.facebook.com/creativestates.io/',
      icon: (<FacebookIcon/>),
    },
    {
      id: 'youtube',
      link: 'https://www.youtube.com/c/creativestates/',
      icon: (<YouTubeIcon/>),
    },
    {
      id: 'blog',
      link: 'https://blog.naver.com/creativestates',
      icon: (<ChromeReaderModeIcon/>),
    },
  ];
  const policyLink = 'https://www.creativestates.io/policy';
  const jobsLink = 'https://creativestates.breezy.hr';

  return (
    <footer className={cn(style.footer, {
      [style.dark]: theme === 'dark'
    })}>
      <Container>
        <div className={style.intro}>
          <div>
            <Logo
              src={theme === 'dark' ? LogoWhiteImg : LogoImg}
              alt={'Creative States'}
              href={'/'}
            />
            <div className={style.aboutUs}>
              <p>
                크리에이티브스테이츠는 나다운 색을 표현하도록 돕는 교육 콘텐츠를 제공합니다.<br/>
                쉽고 다양한 교육으로 일상 속에서 창의력을 마음껏 발휘해보세요.
              </p>
            </div>
          </div>
          <div className={style.company}>
            <h4>Company</h4>
            <p>
              <ExternalLink href={jobsLink}>
                채용
              </ExternalLink>
            </p>
            <p>
              <Link to={'/policy/refund'}>
                환불약관
              </Link>
            </p>
            <p>
              <Link to={'/policy/terms'}>
                개인정보 처리방침/이용약관
              </Link>
            </p>
          </div>
          <div className={style.contactUs}>
            <h4>Contact</h4>
            <p>문의: contact@creativestates.io</p>
            <p>고객센터: 우측 하단 채팅 버튼</p>
            <p>10:00 ~ 18:00 (공휴일 제외, 월~금)</p>
          </div>
          <div className={style.socials}>
            <h4>Socials</h4>
            {socialLinks.map(social => (
              <p key={social.id}>
                <ExternalLink
                  href={social.link}
                  target="_blank"
                >
                  {social.id.charAt(0).toUpperCase() + social.id.slice(1)}
                </ExternalLink>
              </p>
            ))}
          </div>
        </div>
      </Container>

      <hr />

      <Container>
        <div className={style.textBlock}>
          {`상호명 : 주식회사 크리에이티브스테이츠 | 사업자번호 : 491-81-01499 | 통신판매번호: 2019-서울강남-04514 | 대표자명 : 김인기
                    계좌번호 : 케이뱅크 700-166-460001 주식회사 크리에이티브스테이츠 | 사업장주소 : 서울특별시 서초구 서초대로396, 2021호
                    Copyright © 2020. Creative States | 구글 크롬에 최적화 되어 있습니다.`}
        </div>
      </Container>
      {channelTalk && <SecureChannelTalk />}
    </footer>
  );
}

export default Footer;
