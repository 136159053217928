import {
  VIDEO_ADD_FAIL,
  VIDEO_ADD_REQUEST,
  VIDEO_ADD_SUCCESS,
  VIDEO_FAIL,
  VIDEO_REQUEST,
  VIDEO_SUCCESS
} from '../constants/VideoConstants';

function videoReducer(state={}, action) {
  switch (action.type) {
    case VIDEO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        video: {
          ...state.product,
          [action.id]: action.payload,
        }
      };
    case VIDEO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case VIDEO_ADD_REQUEST:
      return {
        ...state,
        add: {
          loading: true
        },
      };
    case VIDEO_ADD_SUCCESS:
      return {
        ...state,
        add: {
          ...action.payload,
          loading: false
        },
      };
    case VIDEO_ADD_FAIL:
      return {
        ...state,
        add: {
          loading: false
        },
      };

    default:
      return state;
  }
}

export {videoReducer};
