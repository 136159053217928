import {
  PRODUCT_CATEGORY_ADD_FAIL,
  PRODUCT_CATEGORY_ADD_REQUEST, PRODUCT_CATEGORY_ADD_SUCCESS,
  PRODUCT_CATEGORY_LIST_FAIL,
  PRODUCT_CATEGORY_LIST_REQUEST,
  PRODUCT_CATEGORY_LIST_SUCCESS
} from '../constants/ProductCategoryConstants';

function productCategoryReducer(state={}, action) {
  switch (action.type) {
    case PRODUCT_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case PRODUCT_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        productCategories: action.payload,
      };
    case PRODUCT_CATEGORY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PRODUCT_CATEGORY_ADD_REQUEST:
      return {
        ...state,
        add: {
          loading: true,
        },
      }
    case PRODUCT_CATEGORY_ADD_SUCCESS:
      return {
        ...state,
        add: {
          loading: false,
          result: 'SUCCESS',
        },
      }
    case PRODUCT_CATEGORY_ADD_FAIL:
      return {
        ...state,
        add: {
          loading: false,
          result: 'ERROR',
          message: action.payload,
        },
      }

    default:
      return state;
  }
}

export {productCategoryReducer};
